import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { UserExperienceDesign, BugVirusMalware, AppDeveloper, PlanningAnalytics, CloudUpload, Speedometer } from '@carbon/pictograms-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const ExpressiveList = makeShortcode("ExpressiveList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Software development is the process of designing, coding, testing, and maintaining software. It is a complex process that involves different stages and requires a range of skills and tools. `}</p>
    </PageDescription>
    <h2>{`Agile Software Development`}</h2>
    <div className="expressive-content-list-group">
  <ExpressiveList background="true" pictogram={<Speedometer mdxType="Speedometer" />} title="A Customer-Focused Approach to Delivering High-Quality Software Products" mdxType="ExpressiveList">
  At Tekdatum, we use agile software development to quickly and efficiently deliver high-quality software solutions. Our process involves analyzing the client's requirements, creating a backlog, prioritizing tasks, breaking them into sprints, and conducting retrospectives. This approach allows us to be flexible and adaptable in a fast-paced business environment, delivering the best possible value to our clients.
  </ExpressiveList>
    </div>
    <h2>{`We work in an agile process with the following stages:`}</h2>

    <div className="expressive-content-list-group">
  <ExpressiveList title="1. Planning and Discovery:" background="true" pictogram={<PlanningAnalytics mdxType="PlanningAnalytics" />} mdxType="ExpressiveList">
  This involves defining the problem the software will solve and identifying the requirements for the software. This stage is critical, as it lays the foundation for the rest of the development process. A software development team will typically work with stakeholders to identify the problem and the requirements for the software.
  </ExpressiveList>
  <ExpressiveList title="2. Design:" background="true" pictogram={<UserExperienceDesign mdxType="UserExperienceDesign" />} mdxType="ExpressiveList">
  Once the requirements for the software have been identified, the next stage is design. In this stage, the software development team will create a design for the software. This may involve creating diagrams or mockups of the user interface, as well as designing the overall architecture of the software.
  </ExpressiveList>
  <ExpressiveList title="3. Iterative Development:" background="true" pictogram={<AppDeveloper mdxType="AppDeveloper" />} mdxType="ExpressiveList">
  After the design has been completed, the software development team will begin coding the software. This involves writing the code that will implement the functionality of the software. Depending on the complexity of the software, this stage may take a significant amount of time. We employ the latest AI technologies to make our coding effort more productive and accurate.
  </ExpressiveList>
    
  <ExpressiveList title="4. Testing and Quality Assurance:" background="true" pictogram={<BugVirusMalware mdxType="BugVirusMalware" />} mdxType="ExpressiveList">
  Once the code has been written, the software development team will begin testing the software. This involves running the software and checking for bugs and errors. Testing is a critical stage of the software development process, as it ensures that the software is working correctly and meets the requirements that were identified in the planning stage. Our team will also conduct user acceptance testing to ensure that the software is intuitive and easy to use.
  </ExpressiveList>
  <ExpressiveList title="5. Deployment and Release:" background="true" pictogram={<CloudUpload mdxType="CloudUpload" />} mdxType="ExpressiveList">
  The final stage of the software development process is deployment. This involves making the software available to users. Depending on the nature of the software, this may involve deploying the software to a web server or making it available for download. Our team will provide ongoing support and maintenance to ensure that the software continues to meet your needs and deliver value over time.
  </ExpressiveList>
    </div>
    <p>{`Overall, our Agile software development process is designed to help you achieve your goals quickly and efficiently, while also ensuring that the software meets your requirements and delivers value to your users. If you’re looking for a partner who can help you bring your vision to life, we’d love to work with you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      